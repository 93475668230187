import React from "react"

import Layout from "../components/layout"
import { StaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { MenuPanels } from "../components/content/menu"

const WhyPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query WhyPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
  <Layout>
    <SEO title="Why?" />

    <div className={"page-header"}> 
      <h2>Why TheLeanFounder?</h2>
    </div>
 
    <div className={"bg-color1"}>
      <div className={"container"}>
        <div className={"integration-guid2"}>
          <div className={"title  text-center"}> 
            <br /> <br />
            <h2 className="text-center text-primary">There are trillions of reasons why...</h2>
          </div>

          <div className={"content"}>
            <div className="container">
              <div className="page-header2">
                <h3 className="text-center text-danger">
                  Trillions in economic losses... 
                </h3>
                <h5 className="text-center text-danger">$2T in economic losses this year in 2020 with estimated $8T by 2022</h5>
                <br />
                <h3 className="text-center text-danger">
                  Billions in regulatory fines... 
                </h3>
                <h5 className="text-center text-danger">$5B in FTC fines paid by Facebook for Cambridge Analytica privacy crisis in 2019</h5>
                <br />   <br /> 
              </div>
              <div className="page-header2"> 
                <h3 className="text-center text-default">
                  People care about trusted, quality technology along with privacy &amp; security &amp; other benefits like AI more than ever...
                </h3>
                {/* <h5 className="text-center text-danger">$5B in FTC fines paid by Facebook for Cambridge Analytica privacy crisis in 2019</h5> */}
                <br /> <br />
              </div>
              <div className="row grid-divider">
                <div className="col-sm-4">
                  <div className="col-padding">
                    <h4 className=" ">Everyone relies on tech &amp; the Internet.</h4>
                    <p>
                      We all depend on the Internet more than ever especially
                      after COVID-19 &amp; coronavirus &amp; work from home.
                    </p>
                    <p>
                      <b>
                        We risk more people not using tech &amp; the Internet for
                        commerce due to many risks, leaving dollars on the
                        table.
                      </b>
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="col-padding">
                    <h4 className="  ">Cyber threats are on the rise.</h4>
                    <p>
                      Ransomware, spyware, identity theft, financial fraud plus
                      data misuse, cyber attacks and data breaches are on the
                      rise.
                    </p>
                    <p>
                      <b>
                        Organizations that collect data must build trust with
                        customers, comply with standards, be more resilient &amp;
                        maintain cyber risk insurance.
                      </b>
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="col-padding">
                    <h4 className="">In Transparency We Must Trust.</h4>
                    <p>
                      People and Business Customers often blindly trust tech
                      companies to safeguard their privacy and data.
                    </p>
                    <p>
                      <b>
                        Customers consent to long policies &amp; user agreements
                        without truly knowing if the associated company can
                        protect their data.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row grid-divider">
              {" "}
              <div className="col-sm-12">
                <br />
                <h3 className="text-center  pt-4 pb-3">
                Our vision


                </h3>  
                <h4 className="text-center  ">
                  Help improve quality, trust and value of the tech industry beginning with our customers.


                </h4>  

                {/* <p className="text-center">
              Bad actors need to be forced to come “into the light”.
            </p> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    
    <div className={"bg-color1  "}>


    <div className="row d-none"> 
              <div className="col-sm-12 pt-4 pb-4"> 
                <MenuPanels />
              </div>
            </div>

    </div>
   
    <div className={"bg-color1  bg-dark text-white d-none"}>

    <div className="container"> 

    <div className="row"> 
              <div className="col-sm-12">
                
                
              <div className="tab-content " id="pills-tabContent">
  <div className="tab-pane fade show active" id="pills-me" role="tabpanel" aria-labelledby="pills-me-tab">
  <br />
                <h4 className="text-center pt-5 pb-4">
                  You need to know which products, sites and apps are
                  not trustable with privacy, personal data, cybersecurity or not aligned with your needs.
                </h4>
     
{/* {process.env.NODE_ENV=="development"  ? ( */}
      <> 
        
 
              {/* <img alt="" src="/images/dpt-problems.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
              <img alt="" src="/images/dpt-privacy-policy-problems.svg" />
              <img alt="" src="/images/dpt-privacy-policy-problems-stats.svg" />
              <img alt="" src="/images/dpt-privacy-iot-problems-stats.svg" />
              <img alt="" src="/images/dpt-privacy-review-problem.svg" />
              <img alt="" src="/images/dpt-privacy-policy-changes-problems.svg" />
              <img alt="" src="/images/dpt-privacy-review-problem-stats.svg" />
              <img alt="" src="/images/dpt-privacy-review-education.svg" />
              <img alt="" src="/images/dpt-fragmented-data.svg" />
              <img alt="" src="/images/dpt-spheres.svg" />
              <img alt="" src="/images/dpt-fragmented-privacy.svg" />
              <img alt="" src="/images/dpt-fragmented-privacy-broken.svg" />
              <img alt="" src="/images/consumer-id-theft.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/dpt-trust-problems.svg" /> 
              <img alt="" src="/images/consumer-fcc.svg" />
              <img alt="" src="/images/consumer-privacy-crisis.svg" />
              <img alt="" src="/images/consumer-change.svg" />
              <img alt="" src="/images/dpt-changes-needed.svg" />
              <img alt="" src="/images/dpt-solution.svg" />
              <img alt="" src="/images/dpt-how-we-help.svg" />
              <img alt="" src="/images/dpt-why-it-matters.svg" />
              <img alt="" src="/images/dpt-people-centered-values.svg" /> */}
      </>
    {/* ) : (
      <>  
      </>
    )} */}

  </div>
  <div className="tab-pane fade" id="pills-customers" role="tabpanel" aria-labelledby="pills-customers-tab">

  <h4 className="text-center pt-5 pb-4">
                 Organizations and businesses need to know which products, sites and apps are
                  not trustable with privacy, personal data, cybersecurity or not aligned with their customer's needs.
                </h4>
                    
{/* {process.env.NODE_ENV=="development"  ? ( */}
      <> 
         
              {/* <img alt="" src="/images/b2b-threats.svg" />
              <img alt="" src="/images/b2b-third-party-risk.svg" />
              <img alt="" src="/images/b2b-third-party-risk-costly.svg" />
              <img alt="" src="/images/b2b-third-party-risk-nth-parties.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
              <img alt="" src="/images/dpt-privacy-policy-changes-problems.svg" />
              <img alt="" src="/images/dpt-privacy-review-problem-stats.svg" />
              <img alt="" src="/images/dpt-changes-needed.svg" />
              <img alt="" src="/images/dpt-vision.svg" />
              <img alt="" src="/images/b2b-why.svg" />
              <img alt="" src="/images/b2b-whynow.svg" />
              <img alt="" src="/images/dpt-why-it-matters.svg" /> */}
 
      </>
    {/* // ) : (
    //   <>  
    //   </>
    // )}
     */}

  </div>
  <div className="tab-pane fade" id="pills-business" role="tabpanel" aria-labelledby="pills-business-tab">


  <h4 className="text-center pt-5 pb-4">
                Service Providers and Data Brokers need to show they are trustable with privacy &amp; personal data &amp; resilient with cybersecurity.
                </h4>
                
{/* {process.env.NODE_ENV=="development"   ? ( */}
      <> 
        
              {/* <img alt="" src="/images/dpt-trust-problems.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
              <img alt="" src="/images/b2b-third-party-risk.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/consumer-fcc.svg" />
              <img alt="" src="/images/dpt-vision.svg" />
              <img alt="" src="/images/b2b-why.svg" />
              <img alt="" src="/images/b2b-whynow.svg" />
              <img alt="" src="/images/dpt-why-it-matters.svg" /> */}
 
      </>
    {/* ) : (
      <>  
      </>
    )} */}


  </div>
  <div className="tab-pane fade" id="pills-partners" role="tabpanel" aria-labelledby="pills-partners-tab">


  <h4 className="text-center pt-5 pb-4">
               Our partners are working to build better ecosystems for their customers and end users as well as build a better, smarter and safer Internet for everyone.
                </h4>

                
      <h5 className="text-center pt-4 pb-4 text-white">TheLeanFounder is excited to help partner organizations everywhere<br />
            we can help your platform or enterprise be efficient with digital protection to be more trusted with customers.</h5>  

                
{/* {process.env.NODE_ENV=="development"  ? ( */}
      <> 
{/*         
              <img alt="" src="/images/dpt-trust-problems.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
 
              <img alt="" src="/images/b2b-third-party-risk.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/dpt-vision.svg" />
              <img alt="" src="/images/b2b-why.svg" />
              <img alt="" src="/images/b2b-whynow.svg" /> */}
      </>
    {/* ) : (
      <>  
      </>
    )} */}
    

  </div>
</div>

              </div>
            </div>
</div>
    </div>

    
    
<div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Let's talk</h2>
                <p>
                  What are your biggest problems? Do you have
                  questions about possible solutions? We'd like to help. 
                </p>
              </div>
              <div className="button">
                <a href="/lets-talk/">Let's talk</a>
              </div>
            </div>
          </div>
{/* 
    <div className={"call-to-action"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target={"_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div> */}
  </Layout>
  </>
    )}
  />
)

export default WhyPage
